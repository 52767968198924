<template>
  <div class="data-cards">
    <router-link v-if="!personal.have_address" :to="{ name: 'profile-personal-address-edit' }" class="data-cards__item data-cards__item--empty">
      <div class="data-cards__title"><span>Адреса</span></div>
      <div class="data-cards__picture">
        <img src="@/assets/img/profile/house-def-icon.svg" alt="">
        <img src="@/assets/img/profile/house-color-icon.svg" alt="">
      </div>
      <div class="data-cards__text">
        На данный момент данные ваших адресов не заполнены, нажмите сюда, чтобы заполнить
      </div>
    </router-link>
    <div v-else class="data-cards__item">
      <div class="data-cards__title">
        <span>Адреса</span>
        <router-link :to="{ name: 'profile-personal-address-edit' }">редактировать</router-link>
      </div>
      <div class="data-cards__picture">
        <img src="@/assets/img/profile/house-color-icon.svg" alt="">
      </div>
      <div class="data-cards__text"><b>Адрес регистрации:</b></div>
      <div class="data-cards__text">{{ personal.registration_address_text ? personal.registration_address_text : '-' }}</div>
      <div class="data-cards__text"><b>Адрес проживания:</b></div>
      <div class="data-cards__text">{{ personal.residence_address_text ? personal.residence_address_text : '-' }}</div>
    </div>
    <router-link v-if="!personal.have_passport" :to="{ name: 'profile-personal-passport-edit' }" class="data-cards__item data-cards__item--empty">
      <div class="data-cards__title"><span>Паспортные данные</span></div>
      <div class="data-cards__picture">
        <img src="@/assets/img/profile/cards-def-icon.svg" alt="">
        <img src="@/assets/img/profile/cards-color-icon.svg" alt="">
      </div>
      <div class="data-cards__text">
        На данный момент ваши паспортные данные не заполнены, нажмите сюда, чтобы заполнить
      </div>
    </router-link>
    <div v-else class="data-cards__item">
      <div class="data-cards__title">
        <span>Паспортные данные</span>
        <router-link :to="{ name: 'profile-personal-passport-edit' }">редактировать</router-link>
      </div>
      <div class="data-cards__picture">
        <img src="@/assets/img/profile/cards-color-icon.svg" alt="">
      </div>
      <div class="data-cards__text"><b>Серия паспорта:</b> {{ personal.passport_series }}</div>
      <div class="data-cards__text"><b>Номер наспорта:</b> {{ personal.passport_number }}</div>
      <div class="data-cards__text"><b>Кем выдан:</b> {{ personal.passport_fms_name }}</div>
      <div class="data-cards__text"><b>Дата выдачи:</b> {{ personal.passport_date }}</div>
    </div>
    <router-link v-if="!personal.have_soc" :to="{ name: 'profile-personal-socials-edit' }" class="data-cards__item data-cards__item--empty">
      <div class="data-cards__title"><span>Социальные сети</span></div>
      <div class="data-cards__picture">
        <img src="@/assets/img/profile/megaphone-def-icon.svg" alt="">
        <img src="@/assets/img/profile/megaphone-color-icon.svg" alt="">
      </div>
      <div class="data-cards__text">
        На данный момент данные ваших социальных сетей не заполнены, нажмите сюда, чтобы заполнить
      </div>
    </router-link>
    <div v-else class="data-cards__item">
      <div class="data-cards__title">
        <span>Социальные сети</span>
        <router-link :to="{ name: 'profile-personal-socials-edit' }">редактировать</router-link>
      </div>
      <div class="data-cards__picture data-cards__picture--hidden">
        <img src="@/assets/img/profile/megaphone-color-icon.svg" alt="">
      </div>
      <ul class="data-cards__socials">
        <li v-if="personal.vk">
          <a :href="personal.vk" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/vk-icon.svg" alt="">
            <span>{{ personal.vk }}</span>
          </a>
        </li>
        <li v-if="personal.facebook">
          <a :href="personal.facebook" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/facebook-icon.svg" alt="">
            <span>{{ personal.facebook }}</span>
          </a>
        </li>
        <li v-if="personal.twitter">
          <a :href="personal.twitter" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/twitter-icon.svg" alt="">
            <span>{{ personal.twitter }}</span>
          </a>
        </li>
        <li v-if="personal.youtube">
          <a :href="personal.youtube" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/youtube-icon.svg" alt="">
            <span>{{ personal.youtube }}</span>
          </a>
        </li>
        <li v-if="personal.instagram">
          <a :href="personal.instagram" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/instagram-icon.svg" alt="">
            <span>{{ personal.instagram }}</span>
          </a>
        </li>
        <li v-if="personal.tiktok">
          <a :href="personal.tiktok" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/tiktok-icon.svg" alt="">
            <span>{{ personal.tiktok }}</span>
          </a>
        </li>
        <li v-if="personal.ok">
          <a :href="personal.ok" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/ok-icon.svg" alt="">
            <span>{{ personal.ok }}</span>
          </a>
        </li>
        <li v-if="personal.telegram">
          <a :href="personal.telegram" target="_blank" class="data-cards__social">
            <img src="@/assets/img/profile/telegram-icon.svg" alt="">
            <span>{{ personal.telegram }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePersonalList',
  data() {
    return {
      personal: {},
    }
  },
  mounted() {
    this.$store.dispatch('profile/personal/GET_DATA')
      .then(response => {
        this.personal = response.data
      })
  },
}
</script>
